.roleTwo::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/sidebarImage.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 30% 55%;
  // opacity: 0.7;
}

.sidebar {
  height: 100%;
  position: relative;
  border-radius: 0 0 0 0;
  overflow: auto;
  ul {
    z-index: 1;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    &.client_logo-ui {
      width: 63%;
      @media (max-width: 1366px){
        width: 80%;
      }
    }
    li {
      // padding: 16px 6px;
      line-height: 1rem;
      // .admin_content {
      // }
      .client_content {
        display: flex;
        align-items: center;
        padding-left: 5px;
      }
      a {
        text-decoration: none;
        color: var(--whiteColor);
        font-size: 0.864rem;
        .client_route-img {
          margin-right: 5px;
        }
        .admin_route-img {
          margin: 0 auto;
        }
        img {
          filter: invert(1);
          display: block;
          width: 30px;
          margin: 0 auto;
          &.logo-image {
            width: 80px;
            filter: none;
            @media (max-width: 767px){
              margin-top: 25px;
            }
          }
          &.client_logo-image {
            width: 100px;
            filter: none;
            border: 1px solid var(--whiteColor);
            border-radius: 10px;
          }
        }
      }
      &.client_common {
        padding: 8px 6px;
        border-end-start-radius: 30px;
        border-start-start-radius: 30px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      &.client_li {
        background-color: var(--tealColor);
      }
      &.admin_li {
        padding: 16px 6px;
      }
      &.active-clientSidebar {
        background-color: var(--orangeColor);
        font-weight: 600;
      }
      &.active-adminSidebar {
        background-color: var(--tealColor);
        font-weight: 600;
        padding: 16px 6px;
      }
    }
    .client_logo-li {
      margin-bottom: 20vh;
      text-align: center;
      display: flex;
      justify-content: end;
      align-items: start;
      padding: 2rem;
    }
  }
  &::-webkit-scrollbar {
    width: 0px
  }
  .closeIcon{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
  }
}

.sidebar1 {
  height: 100%;
  // position: relative;
  padding: 50px 20px;
  overflow: auto;
  border-right: #B9B9B9 1px solid;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &::-webkit-scrollbar {
    width: 0px
  }
  .borderBottom{
    border:#B9B9B9 2px solid;
    margin-top: 8px;
  }
  .upperSection{
    display: flex;
    flex-direction: column;
    gap:30px;
  }
  .lowerSection{
    display: flex;
    flex-direction: column;
    gap:30px;
  }
  img {
    // filter: invert(1);
    display: block;
    width: 100%;
    &.client_logo-image {
      width: 100%;
      filter: none;
      border: 1px solid var(--whiteColor);
      border-radius: 10px;
    }
    &.icon-circle{
      width:80%;
      margin:0 auto;
      cursor: pointer;
    }
    &.menu-icon{
        width:20px;
    }
  }
  .closeIcon{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
  }

  .dropDownMenu{
    position: absolute;
    bottom: 5%;
    left: 5%;
    width: max-content;
    background: #fff;
    box-shadow: 0px 0px 2px #c5c5c5;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  .dropDownItem{
    display: flex;
    gap:10px;
    padding: 10px 25px;
    cursor: pointer;
    &:hover {
      background: linear-gradient(296deg, var(--lightOrangeColor), #efdad180 50%);
  }
  }
}
.roleOne {
  background-color: var(--sidebarColor);
  width: 100px;
  text-align: center;
}
.roleTwo {
  // background-color: var(--sidebarClientColor);
  width: 300px;
  display: flex;
  justify-content: end;
  // background-image: url("../../assets/images/doctorstock.jpg");
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: 70% 50%;
  @media (max-width: 1366px){
    width: 250px;
  }
}
.roleTwo-left0{
  position: fixed;
  top: 0;
  transition: 350ms;
  z-index: 10;
  left: 0;
}
.roleTwo-left-m-100{
  position: fixed;
  top: 0;
  transition: 350ms;
  z-index: 10;
  left: -100%;
}
header {
  position: sticky;
  top: 0;
  z-index: 9;
  .navbar {
    background: var(--whiteColor);
    height: 75px;
    // padding-left: 20px;
    .appIcon {
      margin-top: 3px;
      margin-right: 2px;
    }
  }
  img {
    cursor: pointer;
  }
}

.notificationDropdown {
  justify-content: center;
  align-items: center;
  padding: 0;
  max-width: 40rem;
  .notificationHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 18px;
    min-width: 25rem;
  }
  .notificationBody {
    max-height: 18rem;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0px
    }
  }
  .heading {
    color: var(--darkGrayColor);
    padding: 0;
    margin: 0;
    font-weight: 500;
  }
  .heading1 {
    font-size: 1.3rem;
  }
  .heading2 {
    cursor: pointer;
  }
  .item {
    border-bottom: 1px solid var(--lightGrayColor);
    display: flex;
    // justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: var(--blackColor);
    padding: 15px;
    min-width: 25rem;
    text-wrap: wrap;
    line-height: 21px;
    cursor: pointer;
    .itemIcon {
      border-radius: 50px;
      margin-right: 1rem;
    }
    .time {
      color: var(--grayHeadingColor);
      font-size: 0.8rem;
      padding: 0;
      margin: 0;
    }
    .notificationText {
      padding: 0;
      margin: 0;
      margin-bottom: 0.3rem;
    }
    .closeBtn {
      border-radius: 20px;
      width: 30px;
      padding: 9px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 3px;
    }
    .closeBtn:hover {
      background-color: var(--grayColor);
    }
  }
  .activeItem {
    background-color: var(--lightGrayColor);
  }
  .notifyLoader {
    height: 150px;
  }
  a {
    text-decoration: none;
    color: var(--blackColor);
  }
}

.notifybadge {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
}

.dropdown-menu {
  right: 0;
  left: auto;
}

.breadcrumbs {
  margin-top: 10px;
  ol {
    display: flex;
    // gap: 15px;
    list-style: none;
    padding: 0;
    align-items: center;
    margin-bottom: 0;
    li {
      letter-spacing: 0.5px;
      font-size: 1rem;
      text-transform: uppercase !important;
      @media (max-width: 580px){
        font-size: 0.864rem;
      }
      a {
        text-decoration: none;
        color: var(--tealColor);
      }
      &.active {
        color: var(--tealColor);
        align-items: center;
        display: flex;
        font-size: 1.5rem;
        &::before{
          font-size: 1rem;
        }
      }
    }
  }
}

.__left{
  display: flex;
  align-items: center;
  gap: 5px;
  .search-box {
    background: #e6feff;
    border-radius: 50px;
    height: 40px;
    .search-btn {
      color: var(--darkGrayColor);
      float: right;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: var(--tealColor);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 2s;
    }
    .search-input {
      border: none;
      background: none;
      outline: none;
      float: left;
      padding: 0;
      color: var(--darkGrayColor);
      font-size: 16px;
      transition: 0.4s;
      line-height: 40px;
      width: 320px;
      padding: 0 0 0 15px;
      @media (max-width: 991px){
        width: 200px;
        padding: 0 0 0 15px;
      }
      @media (max-width: 767px){
        width: 0;
        padding: 0;
        font-size: 12px;
      }
    }
    @media (max-width: 991px){
      &:hover > .search-input{
        width: 200px;
        padding: 0 0 0 15px;
      }
    }
    @media (max-width: 767px){
      &:hover > .search-input{
        width: 200px;
        padding: 0 0 0 15px;
      }
    }
    @media (max-width: 380px){
      &:hover > .search-input{
        width: 150px;
        padding: 0 0 0 15px;
      }
    }
  }
}

.userNameText {
  background-color: var(--tealColor);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.loggedinText {
  color: var(--whiteColor);
  text-align: center;
  padding: 4px;
  font-size: 1rem;
  border-radius: 20px;
  background: var(--tealColor);
  background: -webkit-linear-gradient(
    150deg,
    var(--tealColor),
    var(--lightOrangeColor)
  );
  background: linear-gradient(
    150deg,
    var(--tealColor),
    var(--lightOrangeColor)
  );
}



.form-switch .form-check-input {
  width: 50px;
  height: 25px;
  margin-left: 0px;
  cursor: pointer;
  &:checked {
    background-color: var(--orangeColor);
    border-color: var(--orangeColor);
  }
}
.userImgTop{
  border: 1px solid var(--tealColor);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.spinner-border{
  --bs-spinner-border-width: 2px;
  color: #ffffff;
}


.coin-headding{
  font-size: 25px;
}

.coin-button-div{
    height: auto;
    position: absolute;
    top: 40vw;
    left: 37vh
}