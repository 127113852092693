section {
  background-color: var(--bodyColor);
  height: 100vh;
  display: flex;
  .navlink {
    text-decoration: none;
    color: inherit;
  }
  .right-area-client {
    width: calc(100vw - 100px);
    @media (max-width: 1366px) {
      width: calc(100vw - 250px);
    }
  }
  .right-area-admin {
    width: calc(100vw - 100px);
  }
  .right-area-100 {
    width: 100vw;
  }
  .right-area {
    overflow: hidden;
    height: 100%;
    background-color: var(--bodyColor);
    border-radius: 0 0 0 0;

    .main {
      width: 100%;
      margin: 0 auto;
      height: calc(100vh - 75px);
      overflow-y: auto;
      padding: 15px;
    }

    .main-client {
      background: -webkit-linear-gradient(
        272deg,
        var(--lightOrangeColor),
        var(--lightTealColor) 50%
      );
      height: calc(100vh);

      background: linear-gradient(
        272deg,
        var(--lightOrangeColor),
        var(--lightTealColor) 50%
      );
    }
  }
}
